<template>
  <section class="humanware" id="Humanware">
    <v-container class="pa-0" fill-height fluid>
      <v-row no-gutters>
        <v-col class="born-makers" cols="12" md="9">
          <v-img
            alt="laboratory Image"
            height="100%"
            gradient="to top, rgba(36, 37, 41, .3), rgba(36, 37, 41, .3)"
            src="/img/Humanware/born_makers.jpg"
            class="align-center text-center"
          >
            <span class="font-weight-bold born-makers--text">Born Makers</span>
          </v-img>
        </v-col>
        <v-col class="somos-humanware pa-12 text-md-right" cols="12" offset-md="7" md="5">
          <span class="display-1 font-weight-thin d-block">Somos__</span>
          <span class="font-weight-bold special-underscore">____</span>
          <pre class="d-inline display-1 font-weight-bold" style="text-decoration: underline">
H</pre>
          <span class="display-1 font-weight-bold special-text">
            umanware
            <sup>+</sup>
          </span>
          <p class="text-right mt-10 ml-8">
            En Nextik nos consideremos un laboratorio de ideas. Te ayudamos a traer a la realidad tus proyectos de base
            tecnológica. Usando la creatividad y nuestras habilidades, podemos traer a la realidad cualquier idea de
            negocio que tengas en mente.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss">
#Humanware {
  position: relative;
  .born-makers {
    height: calc(100vh - 56px);
    position: absolute;

    .born-makers--text {
      font-size: 3rem;
      color: white;
      text-shadow: 0 10px black;
    }
  }
  .somos-humanware {
    height: calc(100vh - 56px);
    color: white;
    background: rgb(199, 202, 209);
    background: linear-gradient(to bottom, #131b2e, #000104);
    clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%);
    position: absolute;
    // z-index: 1;

    span {
      line-height: 18px;
    }
    .special-underscore {
      position: absolute;
      transform: translate(-28px, 19px);
    }
    .special-text {
      margin-left: -9px;
    }
  }
}
@media screen and (max-width: 960px) {
  #Humanware {
    .born-makers {
      position: relative;
    }
    .somos-humanware {
      height: auto;
      position: relative;
      clip-path: none;
    }
  }
}
</style>
