import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../components/Inicio.vue";
import goTo from "vuetify/es5/services/goto";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home"
    // component: App
  }
];

const router = new VueRouter({
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo, {
      offset: 64
    });
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
