<template>
  <section class="creative" id="Creative">
    <v-container class="pa-0" fill-height fluid>
      <v-row align="center" class="creative-and-young" no-gutters>
        <v-col class="text-center" cols="12">
          <v-img src="img/Creative/Creative.svg" height="115" contain></v-img>
          <span
            class="creative--text font-weight-bold"
            style="text-decoration: underline; letter-spacing: 5px!important;"
            >Creative</span
          >
          <span class="creative--text font-weight-bold mx-5">&</span>
          <span
            class="creative--text font-weight-bold"
            style="text-decoration: underline; letter-spacing: 5px!important;"
            >Young</span
          >
          <span class="creative--text font-weight-bold">.</span>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss">
#Creative {
  display: flex;
  justify-content: center;
  align-items: center;
  .creative--text {
    font-size: 3rem;
  }
}
@media screen and (max-width: 960px) {
  #Creative {
    .creative--text {
      font-size: 2rem;
    }
  }
}
</style>
