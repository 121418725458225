<template>
  <section id="Contacto" class="contact">
    <v-container class="px-10 py-5" fill-height fluid>
      <v-row no-gutters>
        <v-col class="d-flex align-center" cols="12">
          <v-img
              class="d-inline-block"
              contain
              height="30"
              src="img/Contact/spaceship.svg"
              width="30"
          ></v-img>
          <span class="display-1 font-weight-bold">Contacto</span>
        </v-col>
      </v-row>
      <!-- Descripción de formulario -->
      <v-row class="mt-2" justify="center">
        <v-col cols="12" md="10">
          <v-card class="main-card">
            <v-card-title>¿Tienes un proyecto?</v-card-title>
            <v-card-text>
              Nos encantaría ayudarte, mandanos un mensaje para ponernos en contacto contigo.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Formulario -->
      <v-row class="mt-5 justify-center">
        <v-col cols="12" md="10">
          <v-card>
            <v-card-text class="contact-card">
              <v-form ref="contactForm" v-model="isValid" @submit.prevent="sendForm">
                <v-text-field
                    v-model="form.name"
                    :rules="rules.form.name"
                    dense
                    outlined
                    placeholder="Tu nombre"
                    prepend-inner-icon="mdi-account-outline"
                ></v-text-field>
                <v-text-field
                    v-model="form.email"
                    :rules="rules.form.email"
                    dense
                    outlined
                    placeholder="Correo electrónico"
                    prepend-inner-icon="mdi-email-outline"
                ></v-text-field>
                <v-text-field
                    v-model="form.phone"
                    :rules="rules.form.phone"
                    dense
                    outlined
                    placeholder="Teléfono"
                    prepend-inner-icon="mdi-phone-outline"
                ></v-text-field>
                <v-select
                    v-model="form.service_type"
                    :items="service_types"
                    :rules="rules.form.service_type"
                    clearable
                    dense
                    outlined
                    placeholder="Tipo de Servicio"
                    prepend-inner-icon="mdi-toolbox-outline"
                >

                </v-select>
                <v-textarea
                    v-model="form.description"
                    :rules="rules.form.description"
                    no-resize
                    outlined
                    placeholder="Describenos tu proyecto"
                    prepend-inner-icon="mdi-message-reply-text-outline"
                ></v-textarea>
                <v-btn
                    id="btn-send-form"
                    class="px-8"
                    dark
                    small
                    type="submit"
                >Enviar
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Redes Sociales -->
      <v-row>
        <v-col class="ml-5 text-right" cols="10">
          <v-btn href="https://fb.com/NextikLab" icon target="_blank">
            <v-icon>mdi-facebook</v-icon>
          </v-btn>
          <v-btn href="https://instagram.com/nextiklab" icon target="_blank">
            <v-icon>mdi-instagram</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";

export default {
  computed: {
    emailRule() {
      return !!this.form.phone;
    }
  },
  data: () => ({
    form: {
      description: "",
      email: "",
      name: "",
      phone: ""
    },
    isValid: false,
    rules: {
      form: {
        description: [
          v => !!v || "Es necesario que nos describas lo que necesitas"
        ],
        email: [
          v => {
            if (v && v.length > 0) {
              const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
              return pattern.test(v) || "No es un correo válido";
            } else {
              return true;
            }
          }
        ],
        name: [v => !!v || "Es necesario tu nombre"],
        phone: [
          v => !!v || "Es necesario tu número de teléfono o celular",
          v =>
              /^[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/.test(v) ||
              "No es un número de teléfono válido"
        ],
        service_type: [
          v => !!v || "Es necesario que nos especifiques el servicio que necesitas"
        ],
      }
    },
    service_types: [
      {id: 'app', text: 'Aplicaciones móviles'},
      {id: 'hosting', text: 'Hosting (Servidores o Infraestructura)'},
      {id: 'web', text: 'Páginas web'},
      {id: 'network', text: 'Redes y seguridad'},
      {id: 'support', text: 'Soporte técnico'}
    ]
  }),
  methods: {
    sendForm() {
      if (!this.$refs.contactForm.validate()) return;

      axios
          .post("https://api.nextik.mx", this.form)
          .then(response => {
            console.log(response);
            swal(
                "¡Gracias por ponerte en contacto con nosotros!",
                response.data.message,
                "success"
            );
            this.$refs["contactForm"].reset();
          })
          .catch(err => {
            console.log("Error!", err);
            swal("¡Lo sentimos!", err.response.message, "error");
          });
    }
  }
};
</script>

<style lang="scss">
#Contacto {
  #btn-send-form {
    position: absolute;
    bottom: 52px;
    right: 32px;
  }

  .contact-card {
    background-color: black;
  }

  .main-card {
    z-index: 2;
  }

  .v-image {
    max-width: max-content;
  }

  .v-input__slot {
    background: #fff;

    ::placeholder {
      color: black;
    }
  }
}
</style>
