<template>
  <v-app-bar app color="primary" dark>
    <div class="d-flex flex-grow-1 align-center justify-center justify-sm-start">
      <router-link to="/">
        <v-img
            alt="Nextik Logo"
            class="shrink ml-sm-12 ml-0"
            contain
            height="64"
            src="/img/Main/Logo.svg"
            transition="scale-transition"
            width="100"
        />
      </router-link>
    </div>

    <div class="d-none d-sm-block">
      <v-btn :ripple="false" class="px-3 btn-header" text to="#Nosotros">Nosotros</v-btn>
      <div class="header-btn-divider"/>
      <v-btn :ripple="false" class="px-3 btn-header" text to="#Servicios">Servicios</v-btn>
      <div class="header-btn-divider"/>
      <v-btn :ripple="false" class="px-3 btn-header" text to="#Contacto">Contacto</v-btn>
    </div>
  </v-app-bar>
</template>

<script>
export default {};
</script>

<style>
.btn-header {
  font-family: Avant, Futura, sans-serif;
  text-transform: capitalize !important;
}
</style>
