<template>
  <div id="Footer">
    <img :src="logo" height="50"/>
    <div>
      <span> {{ year }} | Nextik lab </span>
      <!--      <small>Todos los derechos reservados.</small>-->
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    year() {
      return new Date().getFullYear();
    }
  },
  data: () => ({
    logo: "img/Footer/nextik_gray.svg"
  })
};
</script>

<style lang="scss">
#Footer {
  background-image: url("/img/Footer/background.svg");
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
}
</style>
