<template>
  <v-content>
    <v-container class="pa-0" fluid id="core-view">
      <Main />
      <Nosotros />
      <Creative />
      <Humanware />
      <Services />
      <hr class="mx-6">
      <Contact />
    </v-container>
    <core-footer />
  </v-content>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  components: {
    Contact: () => import("../Home/Contact"),
    Creative: () => import("../Home/Creative"),
    Humanware: () => import("../Home/Humanware"),
    Main: () => import("../Home/Main"),
    Nosotros: () => import("../Home/Nosotros"),
    Services: () => import("../Home/Services")
  },
  computed: {
    ...mapState(["navbarColor"])
  },
  data: () => ({}),
  metaInfo() {
    return {
      title: "Nextik Lab®"
    };
  },
  methods: {
    ...mapMutations(["setNavbarColor"]),
    handleScroll() {
      // Si está en la primera sección
      if (window.scrollY < window.innerHeight - 64) {
        if (this.navbarColor !== "primary") this.setNavbarColor("primary");
      }
      // Si está en la segunda sección
      else {
        if (this.navbarColor !== "white") this.setNavbarColor("white");
      }
    }
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>
