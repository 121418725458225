<template>
  <section id="Inicio" class="inicio">
    <v-row>
      <v-col class="main-title" cols="12" md="6">
        <img alt="logo_franjas" class="mb-5" height="75" src="/img/Main/ArrowBlack.svg" width="75"/>
        <h1>Think Intelligent.</h1>
        <h1>Be Next<span class="text--disabled">ik</span>.</h1>
      </v-col>
      <v-col class="main-message" cols="12" md="6">
        <p>
          En <span class="font-weight-bold">Nextik</span>
          nos gusta <span>resolver</span> tus
          <span>problemas</span> y <span>mejorar</span> la forma en la que
          <span>tu empresa</span> se comunica.

          <br/>
          <br/>

          Sabemos que los <span>detalles</span> importan por eso nos
          enfocamos en hacer que se noten.

          <br/>
          <br/>

          Ayudamos a que te reconozcan y llegues a <span>tus clientes</span> rápidamente.
          <span>¿Estás listo?</span>
        </p>
        <v-btn ref="noopener" :href="'https://wa.me/52'+contact_phone" class="btn" small target="_blank">
          <v-icon small>mdi-whatsapp</v-icon>
          <span class="ml-2">Contactanos</span>
        </v-btn>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "Inicio",
  computed: {
    contact_phone() {
      return process.env.VUE_APP_CONTACT_PHONE
    }
  },
  components: {}
};
</script>

<style lang="scss">
@import "../../scss/variables.scss";

#Inicio {
  .main-message {
    padding: 64px 32px;
    height: calc(100vh - 56px);
    background-image: url("/img/Main/man-sitting.jpg");
    background-size: cover;
    background-position: top;
    font-family: ITC Avant Garde Gothic Std Book, Futura, sans-serif;

    text-align: justify;

    p {
      color: $gray1;

      span {
        color: white;
      }
    }

    .btn {
      position: absolute;
      bottom: 16px;
      right: 16px;
    }
  }

  .main-title {
    height: calc(100vh - 56px);
    display: flex;
    flex-direction: column;
    font-size: 20px;
    font-family: Mark Pro, sans-serif;
    justify-content: center;

    h1 {
      padding: 0 20% !important;
      line-height: 1.8rem;
      letter-spacing: 4px !important;
      font-weight: bold;
    }

    img {
      margin: 0 auto;
    }
  }
}
</style>
