<template>
  <section id="Proyectos" class="proyectos">
    <v-container>
      <h1 class="text-center display-2">Nuestros clientes</h1>
      <hr class="mb-5" />
      <v-row wrap justify="center">
        <v-col
          cols="10"
          sm="4"
          lg="3"
          v-for="proyecto in proyectos"
          :key="proyecto.nombre"
        >
          <a :href="proyecto.url" target="_blank" rel="noopener noreferrer">
            <v-img
              class="logo_proyecto"
              height="200px"
              :src="proyecto.logo"
              contain
            >
              <p>{{ proyecto.descripcion }}</p>
            </v-img>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data: () => ({
    proyectos: null
  }),
  methods: {
    shuffle(arra1) {
      var ctr = arra1.length,
        temp,
        index;

      // While there are elements in the array
      while (ctr > 0) {
        // Pick a random index
        index = Math.floor(Math.random() * ctr);
        // Decrease ctr by 1
        ctr--;
        // And swap the last element with it
        temp = arra1[ctr];
        arra1[ctr] = arra1[index];
        arra1[index] = temp;
      }
      return arra1;
    }
  },
  mounted() {
    this.proyectos = this.shuffle([
      {
        nombre: "4DNacional",
        logo: "/img/Clientes/4dnacional.png",
        url: "https://4dnacional.mx",
        descripcion:
          "Estadísticas y administración de la liga de 4ta división a nivel nacional."
      },
      {
        nombre: "Fitspiration",
        logo: "/img/Clientes/fitspiration.png",
        url: "https://fitspiration.mx",
        descripcion:
          "Health coach, administración de usuarios, planes alimenticios, noticias"
      },
      {
        nombre: "Foodkard",
        logo: "/img/Clientes/foodkard.png",
        url: "https://registro.foodkard.com",
        descripcion:
          "Membresía que brinda beneficios atractivos y exclusivos a socios, en los restaurantes afiliados."
      },
      {
        nombre: "Urban Grass Landscaping",
        logo: "/img/Clientes/urbangrass.png",
        url: "https://request.urbangrasslandscaping.com/",
        descripcion: "Formulario de solicitud de servicios de jardinería."
      },
      {
        nombre: "SAGO",
        logo: "/img/Clientes/sago.png",
        url: "https://sago.madero.gob.mx",
        descripcion:
          "Sistema de Administración y gobierno para el municipio de Madero, Michoacán."
      }
    ]);
  }
};
</script>

<style lang="scss">
.proyectos {
  display: flex;
  justify-content: center;
  a {
    text-decoration: none;
  }
  .logo_proyecto {
    transition: 0.25s all;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    p {
      transition: 0.25s all;
      display: none;
      text-align: center;
      color: white;
      background: rgba(0, 0, 0, 0.5);
      margin-bottom: 0;
    }
    &:hover {
      transform: scale(1.15);
      p {
        display: block;
      }
    }
  }
}

@media (hover: none) {
  .proyectos {
    .logo_proyecto {
      p {
        display: block;
      }
    }
  }
}
</style>
