<template>
  <v-app>
    <core-navbar></core-navbar>
    <core-view></core-view>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    //
  })
};
</script>

<style lang="scss">
  @import url("./scss/custom.scss");
</style>
