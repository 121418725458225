<template>
  <section id="Servicios" class="services">
    <v-container class="px-10 py-5" fill-height>
      <v-row no-gutters>
        <v-col cols="12">
          <span class="font-weight-bold display-1">Servicios</span>
          <span class="font-weight-bold special-underscore">_______________</span>
        </v-col>
        <v-col cols="12">
          <p
              class="mt-3 mb-5"
          >Tenemos amplia experiencia en variedad de proyectos, ya sea que necesites tener presencia en internet, una
            aplicación de pedidos, un e-commerce completo, soporte para tu empresa o todos los anteriores, en Nextik
            estamos listos para ayudarte a llegar al siguiente nivel.</p>
        </v-col>
      </v-row>
      <v-row>
        <!-- Aplicaciones Móviles -->
        <v-col cols="12" md="6">
          <v-card height="350" hover img="img/Services/cellphone.jpg">
            <v-card-text>
              <h3 class="font-weight-bold">Aplicaciones móviles</h3>
              <h5 class="font-weight-bold">Android & iOS</h5>
              <p class="mt-5">
                Desarrollamos aplicaciones a la medida de tu negocio y de tu presupuesto.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Páginas Web -->
        <v-col cols="12" md="6">
          <v-card height="350" hover img="img/Services/website.jpg">
            <v-card-text>
              <h3 class="font-weight-bold">Páginas web</h3>
              <h5 class="font-weight-bold">
                Landing page, tienda en linea, control para tu negocio,
                facturación
              </h5>
              <p class="mt-4">
                Ya sea que necesites que tener presencia en internet,
                vender productos o gestionar los recursos de toda tu empresa,
                tenemos el producto que te ayudará a cumplir tus objetivos.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Hosting -->
        <v-col cols="12" md="6">
          <v-card height="350" hover img="img/Services/servers.jpg">
            <v-card-text>
              <h3 class="font-weight-bold">Hosting (Servidores o Infraestructura)</h3>
              <h5 class="font-weight-bold">VPS, servidor cloud, servidor dedicado.</h5>
              <p class="mt-4">
                Si necesitas hospedar tu página de web,
                un servidor para juegos en linea o la base de datos de tu empresa,
                tenemos la solución para cada una de tus necesidades.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Soporte Técnico -->
        <v-col cols="12" md="6">
          <v-card height="350" hover img="img/Services/support.jpg">
            <v-card-text>
              <h3 class="font-weight-bold">Soporte técnico</h3>
              <h5 class="font-weight-bold">
                Actualizaciónes de software, reparación/renovación de tus
                equipos.
              </h5>
              <p class="mt-4">
                Planes de mantenimiento preventivo para evitar que
                tus equipos dejen de funcionar, actualizaciones de software para
                que cuentes con las últimos parches de seguridad y
                mantenimiento correctivo en caso de que tus equipos fallen.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Redes y Seguridad -->
        <v-col cols="12" md="6">
          <v-card height="350" hover img="img/Services/network.jpg">
            <v-card-text>
              <h3 class="font-weight-bold">Redes y seguridad</h3>
              <h5 class="font-weight-bold">
                Redes empresariales, servidores, telefonía IP, seguridad para tu
                casa u oficina
              </h5>
              <p class="mt-4">
                Contamos con el stack completo para que tu casa o empresa estén
                seguras, ya sea con sistemas de grabación, control de acceso,
                optimización de tu conexion a internet y el desempeño de tu red
                empresarial.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss">
#Servicios {
  .v-card__text {
    background-color: rgba($color: #000000, $alpha: 0.5);
    color: white;
    height: 100%;

    h5, h6 {
      line-height: 15px;
    }

    p {
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 16px;
      text-align: justify;
    }
  }

  .special-underscore {
    position: absolute;
    transform: translate(-65px, 18px);
  }
}

@media screen and (max-width: 960px) {
  #Servicios {
    .v-card__text {
      p {
        //font-size: .9rem;
      }
    }
  }
}
</style>
